<template>
  <el-container class="camadd">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>监管主体</el-breadcrumb-item>
         <el-breadcrumb-item >监管管理</el-breadcrumb-item>
       <el-breadcrumb-item @click="$router.back()">主体管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.dictionary_id">编辑数据字典</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增数据字典</el-breadcrumb-item>
    </el-breadcrumb>
      <div>
        
        <el-button class="back_btn"
                   size="medium"
                   plain
                   @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="pt0">
      <el-form  ref="form" :model="form" class="demo-ruleForm">
         <el-row class="box mt20  f14">
       <el-col :span="11" class="clear" >
         <el-form-item  label="字典模块名称：" class="w" label-width="28%"  size="medium" prop="dictionary_module" :rules="[{required: true,message: '请选择字典模块名称',trigger: 'blur'}]">
             <el-input v-model="form.dictionary_module"
                    size="medium"
                    clearable></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="clear" >
         <el-form-item  label="字典字段名称：" class="w" label-width="28%"  size="medium" prop="dictionary_name" :rules="[{required: true,message: '请选择字典字段名称',trigger: 'blur'}]">
               <el-input v-model="form.dictionary_name"
                    size="medium"
                    clearable></el-input>
             
        </el-form-item>
        </el-col>
        <el-col :span="11" class="clear" >
       <el-form-item label="字典字段值：" class="w" label-width="28%" 
                      prop='dictionary_field'
                      :rules="[{required: true,message: '请输入字典字段值', trigger: 'blur' }]">
          <el-input v-model="form.dictionary_field"
                    size="medium"
                    clearable></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl"  :offset="1">
        <el-button class size="medium" type="primary" @click="onSubmit('form')">提交</el-button>
        </el-col>
                </el-row>
          
        
      
      </el-form>

    
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import {session} from '../../../util/util'
export default {
  data () {
    return {
      moduleOptions:[],
      nameOptions:[],
      form:{
          dictionary_id:'',
          company_tag_name:'',
          company_tag_id:'',
          dictionary_module:'',
          dictionary_name:'',
          dictionary_field:''
      }
    };
  },
  filters: {},
  created () {
     this.form.company_tag_name =session.get('params2').name
            this.form.company_tag_id =session.get('params2').id?String(session.get('params2').id):'';
             this.form.dictionary_id =session.get('params2').id1?String(session.get('params2').id1):'';
          // if(this.form.company_tag_id==1){
          //    this.moduleOptions=[{ dictionary_module: "经营信息", value: "1" }]
          //      this.nameOptions=[{dictionary_module:'经营信息',dictionary_name: "生产形式", value: "1" },{ dictionary_module:'经营信息',dictionary_name: "生产类别", value: "2" }]
          // }else if(this.form.company_tag_id==2||this.form.company_tag_id==4){
          //    this.moduleOptions=[{ dictionary_module: "场地信息", value: "1" }]
          //    this.nameOptions=[{ dictionary_module: "场地信息",dictionary_name: "场地分布", value: "1" }]
          // }else if(this.form.company_tag_id==5){
          //    this.moduleOptions=[{ dictionary_module: "经营信息", value: "1" }]
          //     this.nameOptions=[{dictionary_module: "经营信息", dictionary_name: "经营范围", value: "1" },{ dictionary_module: "主体业态",dictionary_name: "食品经营许可", value: "2" }]
          // }else if(this.form.company_tag_id==3){
          //    this.moduleOptions=[{ dictionary_module: "场地信息", value: "1" },{ dictionary_module: "主体业态",value: "2" }]
          //    this.nameOptions=[{ dictionary_module: "场地信息",dictionary_name: "场地分布", value: "1" },{ dictionary_module: "场地信息",dictionary_name: "食品制售", value: "2" },{ dictionary_module: "主体业态",dictionary_name: "食品经营许可", value: "3" }]

          // }
        if(this.form.dictionary_id){ this.getDate()}//编辑获取回显信息
   },
  methods: {
      getDate(){
          axios.get("/pc/dictionary/single",{dictionary_id:String(this.form.dictionary_id)}).then(v => {
           this.form.dictionary_module=v.data.dictionary.dictionary_module;
           this.form.dictionary_name=v.data.dictionary.dictionary_name
           this.form.dictionary_field=v.data.dictionary.dictionary_field
          });
      },
      handleChange(){
        this.form.dictionary_name='';
         this.form.dictionary_field=''
      },
        onSubmit (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
           if(this.form.dictionary_id){
               axios.put("/pc/dictionary/update",this.form).then(response => {
              this.$router.back();
            })}else{
              axios.post("/pc/dictionary/save",this.form).then(response => {
              this.$router.back();
            })
           }
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
    exist (sensor_number) {
      if (sensor_number != null && sensor_number.length > 0) {
        axios.get("/api/sensor/exist", { sensor_number: sensor_number }).then(v => {
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.camadd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  .form-container {
    margin-top: 20px;
    padding-right: 35px;
    /deep/ .el-form-item__content {
      width: 50%;
    }
  }
  /deep/ .el-radio__label {
    display: none !important;
  }
  /deep/ .el-dialog__body {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
    border-color: #e4e7ed;
    color: #606266;
    cursor: not-allowed;
  }
  /deep/ .el-dialog {
    width: 60% !important;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /* .el-form-item--medium .el-form-item__content{width: 80%} */
}
</style>
